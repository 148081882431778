import React, { useState, useContext } from 'react';
import { Col, Row, Tabs, Tab } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Page from '../../components/page';
import { ContextUser } from '../../providers/ContextUser';
import PageVoter from '../../components/voter';
import { api } from '../../providers/apiClient';
import { CustomTabTitle, CustomTabQuantity } from './styles';
import PageVoterVC from '../../components/voter-vc';

const PageLeader = () => {
  const { currentUser } = useContext(ContextUser);
  const navigate = useNavigate();
  const [tab, setTab] = useState('voters');
  const [isFetching, setIsFetching] = useState(true);
  const [voters, setVoters] = useState([]);
  const [votersVC, setVotersVC] = useState([]);
  const [qtdVoters, setQtdVoters] = useState(0);
  const [qtdVotersVC, setQtdVotersVC] = useState(0);
  const [qtdVotersFK, setQtdVotersFK] = useState(0);

  function fetchVoters() {
    api
      .get('/votersTC')
      .then(response => {
        setVoters([]);
        setVotersVC([]);
        setVoters(response.data.desnormalizedVoterList);
        setVotersVC(
          response.data.desnormalizedVoterList.filter(
            voter => voter.type === 'VC'
          )
        );
        setQtdVoters(
          response.data.desnormalizedVoterList.filter(
            voter => voter.type === 'AP'
          ).length
        );
        setQtdVotersVC(
          response.data.desnormalizedVoterList.filter(
            voter => voter.type === 'VC'
          ).length
        );
        setQtdVotersFK(response.data.totalQtdFK);
        setSearchVoterResult(response.data.desnormalizedVoterList);
      })
      .catch(() => {})
      .finally(() => {});
  }

  return (
    <>
      <Page title="Liderança">
        <div>
          <Tabs
            id="controlled-tabs"
            activeKey={tab}
            onSelect={currentTab => setTab(currentTab)}
          >
            <Tab
              eventKey="voters"
              title={
                <CustomTabTitle>
                  <CustomTabQuantity value={qtdVoters + qtdVotersFK}>
                    {qtdVoters + qtdVotersFK}
                  </CustomTabQuantity>
                  Apoiadores
                </CustomTabTitle>
              }
              style={{
                padding: '10px',
                border: '1px solid #dee2e6',
                borderTop: '0px',
                marginTop: 0,
                marginBottom: '10px',
                borderBottomLeftRadius: '5px',
                borderBottomRightRadius: '5px'
              }}
            >
              <PageVoter
                voters={voters.filter(voter => voter.type === 'AP')}
                fetchVoters={fetchVoters}
                ownerId={currentUser?.id}
                fetchDashboardData={() => console.log('fetchDashboardData')}
              />
            </Tab>

            {currentUser?.enabledVC && (
              <Tab
                eventKey="votersVC"
                title={
                  <CustomTabTitle>
                    <CustomTabQuantity value={qtdVotersVC}>
                      {qtdVotersVC}
                    </CustomTabQuantity>
                    VotoCerto
                  </CustomTabTitle>
                }
                style={{
                  padding: '10px',
                  border: '1px solid #dee2e6',
                  borderTop: '0px',
                  marginTop: 0,
                  marginBottom: '10px',
                  borderBottomLeftRadius: '5px',
                  borderBottomRightRadius: '5px'
                }}
              >
                <PageVoterVC
                  voters={votersVC}
                  fetchVoters={fetchVoters}
                  ownerId={currentUser?.id}
                  fetchDashboardData={() => console.log('fetchDashboardData')}
                />
              </Tab>
            )}
          </Tabs>
        </div>
      </Page>
    </>
  );
};

export default PageLeader;
